import React from "react";
import Layout from "../components/Layout/Layout";
import ImprintMain from "../components/ImprintUiComponents/ImprintMain";
import { SEO } from "../components/seo";

const Imprint = () => {
  return (
    <Layout location="/blog">
      <SEO
        title="Imprint"
        description="Imprint - Information required under § 5 Para. 1 TMG"
        type="website"
      />
      <ImprintMain />
    </Layout>
  );
};

export default Imprint;
