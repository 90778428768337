import { Link } from "gatsby";
import React from "react";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import * as styles from "./Imprint.module.scss";
import translate from "../../i18n/translate";

const ImprintMain = () => {
  return (
    <InnerWrapper>
      <div className={styles.imprintWrapper}>
        <h1 className="heading">
          <span>.</span>
          {translate("imprint-title")}
        </h1>
        <div className={styles.imprintBody}>
          <h4>{translate("imprint-info")}</h4>
          <div className={styles.imprintAddress}>
            <h4>globaldatanet GmbH</h4>

            <span>Alter Teichweg 11-13</span>
            <span>22081 Hamburg</span>
          </div>

          <div className={styles.imprintPresented}>
            <span>{translate("imprint-chief")}</span>
            <Link to={"/our-team/marc-schrter"}>Marc Schröter</Link>
          </div>

          <div className={styles.imprintContact}>
            <h4>{translate("imprint-contact")}</h4>
            <span>
              {translate("imprint-phone")}{" "}
              <a href="tel:004988369785">+49 40 88369785 </a>
            </span>
            <span>
              {translate("imprint-email")}{" "}
              <a href="mailto:hello@globaldatanet.com">
                hello@globaldatanet.com
              </a>
            </span>
            <span>
              Internet:{" "}
              <a href="https://globaldatanet.com">https://globaldatanet.com</a>
            </span>
          </div>

          <div className={styles.imprintRegistration}>
            <h4>{translate("imprint-register")}</h4>
            <span>{translate("imprint-court")} Amtsgericht Hamburg</span>
            <span>{translate("imprint-number")} HRB 147716</span>
          </div>

          <div className={styles.imprintTax}>
            <h4>{translate("imprint-tax")}</h4>
            <span>{translate("imprint-taxID")}</span>
            <span>DE313499363</span>
            <span>{translate("imprint-taxNo")}</span>
            <span>43/725/01656</span>
          </div>

          <div className={styles.imprintAddress}>
            <h4>globaldatanet d.o.o.</h4>

            <span>Oračka 4</span>
            <span>11000 Beograd</span>
          </div>
          <div className={styles.imprintContact}>
            <h4>{translate("imprint-contact")}</h4>
            <span>
              {translate("imprint-phone")}{" "}
              <a href="tel:00381641535626">+381 64 1535 626</a>
            </span>
          </div>
          <div className={styles.imprintTax}>
            <h4>{translate("imprint-tax")}</h4>
            <span>{translate("imprint-taxID")}</span>
            <span>112085215</span>
            <span>{translate("imprint-taxNo")}</span>
            <span>21604453</span>
          </div>
        </div>
      </div>
    </InnerWrapper>
  );
};

export default ImprintMain;
